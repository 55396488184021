.plans.container{
     margin-top: -4rem;
     padding: 0 2rem;
     display: flex;
     flex-direction: column;
     gap: 4rem;
     position: relative;
     padding-top: -5rem;
}

.plans-blurA{
    width: 32rem;
    height: 23rem;
    top: 6rem;
    left:0rem;
}


.plans-blurB{
    width: 35rem;
    height: 150rem;
    top: 150rem;
    right: 0rem;
}

.plans{
    display: flex;
    padding-top: 5rem;
    align-items: center;
    justify-content: center;
    gap: 4rem;
}
.plan{
    display: flex;
    flex-direction: column;
    background: var(--caloryCard);
    color: white;
    gap:3.25rem;
    padding:2.5rem;
    padding-left: 2.2rem;
    width: 19rem;
}

.plan:nth-child(2){
    background: var(--planCard);
    transform: scale(1.1);
}
.plan>svg{
    fill: var(--orange);
    width: 3rem;
    height: 2.5rem;
}
.plan>:nth-child(2){
    font-size: 1.5rem;
    font-weight: bold;
}

.plan>:nth-child(3){
    font-size: 4rem;
    font-weight: bold;
}

.plan>:nth-child(5){
    font-size: 0.8rem;
}

.features{
    display:flex;
    flex-direction: column;
    gap: 1rem;
    font-size: 1.25rem;
}
.feature{
    display:flex;
    align-items:center;
    gap:1rem;
}

.feature>img{
    width:2rem;
}

.plans>:nth-child(2)>svg{
    fill:white;
}

.plans>:nth-child(2)>button{
    color:orange;
}

@media screen and (max-width:768px){
    .plans{
        flex-direction: column;
    }
    .plans>:nth-child(2){
        transform: none;
    }

}