.Reasons{
    padding:0 2rem;
    display: flex;
    grid-template: 2rem;
    padding-top: 6.5%;

}

.left-r{
    flex: 1 1;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 1rem;
    grid-auto-rows: 1fr;
}

.left-r>img{
    object-fit: cover;
}

.left-r>:nth-child(1){
    width: 17rem;
    padding-right: 2rem;
    grid-row: 1/3;
    height: 38rem;
}


.left-r>:nth-child(2){
    width: 35rem;
    height: 21rem;
    grid-column: 2/11;
    padding-bottom: 0.25rem;
}

.left-r>:nth-child(3){
    width: 20rem;
    grid-column: 2/3;
    grid-row: 2;
}
    

.left-r>:nth-child(4){
    width: 14rem;
    grid-row:2;
    grid-column: 3/4;
   
    
}


.right-r{
    flex:1 1;
    text-transform: uppercase;
    gap: 1rem;
    display: flex;
    flex-direction: column;
}

.right-r>span{
    font-weight: bold;
    color: var(--orange);
    font-size: 1.25rem;
}

.right-r>div{
    color: white;
    font-size: 3.8rem;
    font-weight: bold;
}

.details.r{
    display: flex;
    flex-direction: column;
    gap: 4rem;
}

.details-r>div{
    display: flex;
    font-size: 1.25rem;
    gap: 1rem;
    line-height: 2.85;
}

.details-r>div>img{
    width: 3rem;
    height: 2.75rem;
    line-height: 100;
    padding-bottom: 3rem;
    
}

.partners{
    display: flex;
    gap: 1rem;
    width: 2.5rem;
}

@media screen and (max-width:768px){
    .Reasons{
        flex-direction: column;
    }
    .details-r>div{
        font-size: 1rem;
        gap: 0rem;
        line-height: 1.5rem;
    }
    .left-r{
        grid-auto-rows: auto;
        overflow: hidden;
        padding-bottom: 2rem;
        gap: -0rem;
    }
    .left-r> :nth-child(1){
        width: 7rem;
        height: 17rem;
    }
    .left-r> :nth-child(2){
        width: 10rem;
        height: 8rem;
    }
    .left-r> :nth-child(3){
        width: 9rem;
    }
    .left-r> :nth-child(4){
        width: 7rem;
        height: 6rem;
    }
    .details-r{
        padding-bottom: -1rem;
    }
}
