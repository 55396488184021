.Programs{
    display: flex;
    flex-direction: column;
    gap: 2rem;
    padding:0 2rem;
}
.programs-header{
    display: flex;
    gap: 5rem;
    font-weight: bold;
    font-style: 3rem;
    justify-content: center;
    color:white;
    text-transform: uppercase;
    font-style: italic;
    font-size: 400%;
    padding-right: 2%;
    padding-top: 6%;
}

.program-categories{
    display: flex;
    gap: 1.25rem;
}

.category{
    display: flex;
    flex-direction: column;
    background-color: gray;
    padding: 2rem;
    gap: 1rem;
    line-height: 200%;
    color: white;
    justify-content: space-between;
}

.category >:nth-child(1)
{
    width: 2.5rem;
    height:3rem; fill: white;
}

.category >:nth-child(2)
{
    font-size: 1.25rem;
    font-weight: bold;
}

.category >:nth-child(3)
{
    font-size: 1.15rem;
    line-height: 30px;
}

.join-now{
    display: flex;
    align-items: center; gap: 2rem;
    font-size: 130%;
}

.join-now>img{
    width: 1rem;
}

.category:hover{
    background-color: var(--orange);
    cursor: pointer;
}
@media screen and (max-width:768px){
    .programs-header{
        flex-direction: column;
        gap: 1rem;
        font-size: x-large;
        align-items: center;
        justify-content: center;
        margin-top: 2rem;
    }
    .program-categories{
        flex-direction: column;
    }
}
